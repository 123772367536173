import React, { useState, useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Form, FormGroup, Label, Input, UncontrolledAlert, Col, Row, Card, CardBody } from 'reactstrap';
import { insertDataJson, getData, updateItem } from '../../vibe/helpers/helpers'
import { getCookie } from '../../vibe/helpers/AuthenticatedComponent'

const RequisitosTramites = ( { location } )=>{
  const [actualizar, setActualizar] = useState(false);
  const [requisitoid, setRequisitoID] = useState(0);
  const [tramite, setTramite] = useState({});
  const [datosform, setDatosForm] = useState({
    desc_requisito_html:'',
    desc_requisito: '',
    tramite_id: 0,
    status: 0
  })
  const {
    desc_requisito_html,
    tramite_id
  } = datosform;
  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });


  const submitForm = async e =>{
    actualizaMensaje({
      status:false
    })
    e.preventDefault();
    const queryString = require('query-string');
    const { id } = queryString.parse(location.search);
    if(desc_requisito_html === '' || id === 0){
      actualizaMensaje({
        status:true,
        label: "warning",
        message: "Todos los campos son requeridos"
      })      
      return;
    }
    let datos = {
      desc_requisito_html: desc_requisito_html,
      tramite_id: id,
      status: 0
    }
    const ruta = 'requisito';
    if(actualizar){
      const send = await updateItem(ruta+'/'+requisitoid,datos);
      if(send.code === 200){
        actualizaMensaje({
          status:true,
          label: "success",
          message: "Se ha guardado correctamente"
        })
        getRequisito();
        return;
      }
    }else{
      const send = await insertDataJson(ruta,datos);
      if(send.code === 201){
        actualizaMensaje({
          status:true,
          label: "success",
          message: "Se ha guardado correctamente"
        })
        getRequisito();
        return;
      }
    }

    actualizaMensaje({
      status:true,
      label: "danger",
      message: "Algo ha ocurrido y no se guardaron los datos"
    })
  }

  const descTramite =(desc_requisito_html)=>{
    console.log(desc_requisito_html)
    setDatosForm({
      ...datosform,
      desc_requisito_html : desc_requisito_html
    });
  }

  const getRequisito = async()=>{
    const queryString = require('query-string');
    const { id } = queryString.parse(location.search);
    const send = await getData('requisito/'+id);
    console.log(send);
    if(send.data){
      setDatosForm({
        ...datosform,
        desc_requisito_html : send.data.desc_requisito_html
      });
      setRequisitoID(send.data.id);
      setActualizar(true);
      return;
    }
  }

  const getTramite = async()=>{
    const queryString = require('query-string');
    const { id } = queryString.parse(location.search);
    const send = await getData('tramite/'+id);
    console.log(send);
    if(send.data){
      setTramite(send.data)
      return;
    }
  }

  useEffect(()=>{
    getRequisito();
    getTramite();
  },[]);

  return(
  <Row>
    <Col md={{ size: 8, offset: 2 }}>
      <Card>
      <CardBody>
        <h4>Támite: <b>{ tramite.desc_tramite }</b></h4>
        <hr/>
        <Form
          onSubmit={submitForm}
        >
        {
          mensaje.status ?
            <UncontrolledAlert color={mensaje.label}>
              {mensaje.message}
            </UncontrolledAlert>
          : null
        }
        <label>Descripción de los requisitos:</label>
        <CKEditor
          editor={ ClassicEditor }
          data={desc_requisito_html === "" || !desc_requisito_html ? 'Escribe aquí la descripción' : desc_requisito_html}
          onReady={ editor => {
              // You can store the "editor" and use when it is needed.
              console.log( 'Editor is ready to use!', editor );
          } }
          onChange={ ( event, editor ) => {
              const data = editor.getData();
              descTramite(data);
          } }
        />
        {
          actualizar ? 
            <Button color="warning" type="buttom">Actualizar</Button>
          :
            <Button color="primary" type="buttom">Guardar</Button>
        }
        </Form>
      </CardBody>
      </Card>
    </Col>
  </Row>
  )
}

export default RequisitosTramites