import React, { useState, useEffect } from 'react'
import { Button, Form, FormGroup, Label, Input, UncontrolledAlert, Col, Row, Card, CardBody, Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import Calendar1 from '../../assets/images/calendar1.png'
import Calendar2 from '../../assets/images/calendar2.png'
import { getData } from '../../vibe/helpers/helpers'

const Seguimiento = ()=>{
  const [solicitudes, setSolicitudes] = useState([]);
  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });

  const getSolicitudes = async()=>{
    const send = await getData('solicitud');
    console.log(send);
    if(send.data){
      setSolicitudes(send.data)
      return;
    }
  }

  useEffect(()=>{
    getSolicitudes();
  },[]);


  const [columnastablas, setColumnasTablas] = useState(
    [
      // { title: "Tipo", field: "desc_tipo_tramite" },
      { 
        title: "Folio", 
        field: "folio", 
        render: (row)=><NavLink to={`/detalle/?id=${row.id}`}>{ row.folio }</NavLink>
      },
      { title: "Trámite", field: "desc_tramite" },
      { title: "Nombre", field: "nombre_completo" },
      { title: "Matricula", field: "matricula" },
      { title: "Teléfono", field: "descripcion_solicitud" },
      { title: "Departamento", field: "desc_departamento" },
      { title: "Fecha Solicitud", field: "fecha_solicitud" },
      { title: "Hora Solicitud", field: "hora_solicitud" },
      { 
        title: "Estatus", 
        field: "desc_estatus",
        render: (row)=><Badge color={ row.desc_estatus === 'Agendado' ? 'info' :  row.desc_estatus === 'Atendido' ? 'primary' : row.desc_estatus === 'Para entrega' ? 'success' :  row.desc_estatus === 'Agendado para entrega' ? 'info' :  row.desc_estatus === 'Cancelado' ? 'danger' : 'warning' } >{ row.desc_estatus }</Badge>
      },
      { 
        title: "Agendar", 
        render: (row)=>
        <Row>
          <Col md={{ size: 6 }}>
            <a href = { `/calendario/?calendario=1&fol=${row.folio}&actualizar=false` }><img width={50} src={ Calendar1 } /></a>
          </Col>
          <Col md={{ size: 6 }}>
          <a href = { `/calendario2/?calendario=2&fol=${row.folio}&actualizar=false` }><img width={50} src={ Calendar2 } /></a>
          </Col>
        </Row>
      }
    ]
  );


  return(
  <Row>
    <Col md={{ size: 12 }}>
      <Card>
        <CardBody>
          <MaterialTable
            options={{
              headerStyle:{fontSize:'12px'},
              bodyStyle:{fontSize:'12px'},
              pageSize: 10
            }}
            title="Seguimiento Solicitudes"
            columns={columnastablas}
            data={solicitudes}
            onRowSelected
          />
        </CardBody>
      </Card>
    </Col>
  </Row>
  )
}

export default Seguimiento