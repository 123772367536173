import React, { useState, useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, UncontrolledAlert, Col, Row, Card, CardBody, Table } from 'reactstrap';
import { insertDataJson } from '../../vibe/helpers/helpers'
import * as XLSX from "xlsx";

const SubirEntrega = ()=>{
  const [folios, setFolios] = useState([]);
  const [solicitud, setSolicitud] = useState({
    email:'',
    password: ''
  })
  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });


  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log(d);
      setFolios(d);
    });
  };

  const submitForm = async e =>{
    actualizaMensaje({
      status:true,
      label: "primary",
      message: "Actualizando folios..."
    });

    if(folios.length <= 0){
      actualizaMensaje({
        status:true,
        label: "warning",
        message: "No ha importado ningún folio"
      });
      return;
    }
    e.preventDefault();
    let datos = {
      folios_entrega: folios
    }
    const ruta = 'solicitud-entrega';
    const send = await insertDataJson(ruta,datos);
    if(send.code === 200){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se han actualizado los folios para Entrega"
      });
      return;
    }

    actualizaMensaje({
      status:true,
      label: "danger",
      message: "Algo ha ocurrido y no se guardaron los datos"
    });
  }

  return(
  <Row>
    <Col md={{ size: 8, offset: 2 }}>
      <Card>
        <CardBody>
          {
            mensaje.status ?
              <UncontrolledAlert color={mensaje.label}>
                {mensaje.message}
              </UncontrolledAlert>
            : null
          }
          <Form
            onSubmit={submitForm}
          >
            <Label for="name">Seleccion un archivo .xlsx</Label>
              <input
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            />
            {
              folios.length > 0 ?
                <Button style={{ alignSelf: 'center' }} color="primary" type="buttom">Enviar Folios</Button>
              : null
            }
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
        </CardBody>
        <Table>
          <thead>
            <tr>
              <th>Folios para Entrega</th>
              <th><a href="/entrega.xlsx"> Descargar plantilla xlsx</a></th>
            </tr>
          </thead>
          <tbody>
            {
              folios.map(item=>(
                <tr key ={ item.folios_entrega }>
                  <td>
                    <p>{ item.folios_entrega }</p>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Card>
    </Col>
  </Row>
  )
}

export default SubirEntrega