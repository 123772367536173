import React, { useState, useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, UncontrolledAlert, Col, Row, Card, CardBody } from 'reactstrap';
import { login } from '../../vibe/helpers/helpers'
import { setCookie } from '../../vibe/helpers/AuthenticatedComponent'

import { AppContext } from '../../contextApp'

const Login = ()=>{
  const [user, actualizaUser] = useState({
    email:'',
    password: ''
  })
  const {email, password} = user;
  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });

  const actualizaState = e =>{
    actualizaUser({
      ...user,
      [e.target.name] : e.target.value
    })
  }

  const submitLogin = async e =>{
    actualizaMensaje({
      status: true,
      label: 'primary',
      message: 'Iniciando sesión...'
    })
    e.preventDefault();
    const sendUser = await login('login',user);
    console.log(sendUser.user);
    if(sendUser.user){
      var usr = {
        id: sendUser.user.id,
        name: sendUser.user.name,
        email: sendUser.user.email,
        admin: sendUser.user.admin,
        dept: sendUser.user.departamento_id,
      }
      usr = JSON.stringify(usr);
      setCookie('STORAGE_KEY_GOLSAGE', sendUser.access_token, 1, usr);
      window.location = '/reportes-cita';
    }

    actualizaMensaje({
      status:true,
      label: "danger",
      message: sendUser.message
    })
    return;
    window.location = '/login';
  }

  return(
  <Row>
    <Col md={{ size: 8, offset: 2 }}>
      <Card>
      <CardBody>
      <Form
        onSubmit={submitLogin}
      >
        {
          mensaje.status ?
            <UncontrolledAlert color={mensaje.label}>
              {mensaje.message}
            </UncontrolledAlert>
          : null
        }
        <FormGroup>
          <Label for="name">Usuario</Label>
          <Input type="text" name="email" id="email" placeholder="Usuario" value={email} onChange={actualizaState} />
        </FormGroup>
        <FormGroup>
          <Label for="name">Contraseña</Label>
          <Input type="password" name="password" id="password" placeholder="Contraseña" value={password} onChange={actualizaState} />
        </FormGroup>
        <Button type="buttom">Iniciar Sesión</Button>
      </Form>
      </CardBody>
      </Card>
    </Col>
  </Row>
  )
}

export default Login