import React, { useState, useContext, useEffect, Fragment } from 'react'
import { Button, Form, FormGroup, Label, Input, UncontrolledAlert, Col, Row, Card, CardBody } from 'reactstrap';
import { insertDataJson, getData, updateItem } from '../../vibe/helpers/helpers'
import { getCookie } from '../../vibe/helpers/AuthenticatedComponent'

const Solicitud = ( { location } )=>{
  const [tipotramites, setTipoTramites] = useState([]);
  const [tramites, setTramites] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [estatuses, setEstatuses] = useState([]);
  const [datosform, setDatosForm] = useState({});
  const [mensaje, actualizaMensaje] = useState({
    status: false,
    message:'',
    label: 'danger'
  });
  
  const actualizaState = e =>{
    setDatosForm({
      ...datosform,
      [e.target.name] : e.target.value
    })
  }

  const submitForm = async e =>{
    const usr = JSON.parse(getCookie('USER_GOLSAGE'));
    actualizaMensaje({
      status:true,
      label: "primary",
      message: "Guardando...."
    })
    e.preventDefault();
    let datos = {
      'descripcion_solicitud': datosform.descripcion_solicitud,
      'status': datosform.status,
      'tipo_tramite_id': datosform.tipo_tramite_id,
      'tramite_id': datosform.tramite_id,
      'user_id': datosform.user_id,
      'departamento_id': datosform.departamento_id,
      'estatus_id': datosform.estatus_id,
      'nombre_completo': datosform.nombre_completo,
      'matricula': datosform.matricula,
      'user_id': usr.id
    }
    const ruta = 'solicitud';
    const send = await updateItem(ruta+'/'+datosform.id,datos);
    if(send.code === 200){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha guardado correctamente"
      })
      setTimeout(()=>{
        actualizaMensaje({
          status:false,
          label: "",
          message: ""
        })
      }, 2000);

      return;
    }

    actualizaMensaje({
      status:true,
      label: "danger",
      message: "Algo ha ocurrido y no se guardaron los datos"
    })
  }

  const getDetalleSolicitud = async()=>{
    actualizaMensaje({
      status:true,
      label: "warning",
      message: "Consultando...."
    })
    const queryString = require('query-string');
    const { id } = queryString.parse(location.search);
    const send = await getData('solicitud/'+id);
    console.log(send);
    if(send.data){
      setDatosForm(send.data)
      actualizaMensaje({
        status:false,
        label: "",
        message: ""
      })
      return;
    }

    actualizaMensaje({
      status:true,
      label: "danger",
      message: "Ha ocurrido algo"
    })
  }

  const getTipo = async()=>{
    const send = await getData('tipo-tramite');
    console.log(send);
    if(send.data){
      setTipoTramites(send.data)
      return;
    }
  }
  const getTramite = async()=>{
    const send = await getData('tramite');
    console.log(send);
    if(send.data){
      setTramites(send.data)
      return;
    }
  }
  const getDepartamento = async()=>{
    const send = await getData('departamento');
    console.log(send);
    if(send.data){
      setDepartamentos(send.data)
      return;
    }
  }
  
  const getEstatus = async()=>{
    const send = await getData('estatus');
    console.log(send);
    if(send.data){
      setEstatuses(send.data)
      return;
    }
  }

  useEffect(()=>{
    getDetalleSolicitud();
    getTipo();
    getTramite();
    getDepartamento();
    getEstatus();
  },[]);
  

  return(
  <Row>
    <Col md={{ size: 8, offset: 2 }}>
      <Card>
      <CardBody>
        <h4>Folio: <b>{datosform.folio}</b></h4>
      <Form
        onSubmit={submitForm}
      >
        {
          mensaje.status ?
            <UncontrolledAlert color={mensaje.label}>
              {mensaje.message}
            </UncontrolledAlert>
          : null
        }
        <FormGroup>
          <Label for="tipo_tramite_id">Tipo</Label>
          <Input 
            type="select" 
            name="tipo_tramite_id" 
            id="tipo_tramite_id"
            onChange = {actualizaState}
            value = {datosform.tipo_tramite_id}
            >
            <option value="0" >Seleccion una opción </option>
            {
              tipotramites ? 
                tipotramites.map(item=>(
                  <option value={item.id} >{ item.desc_tipo_tramite }</option>
                ))
              : null
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="tramite_id">Trámite</Label>
          <Input 
            type="select" 
            name="tramite_id" 
            id="tramite_id"
            onChange = {actualizaState}
            value = {datosform.tramite_id}
            >
            <option value="0" >Seleccion una opción </option>
            {
              tramites ? 
                tramites.map(item=>(
                  <option value={item.id} >{ item.desc_tramite }</option>
                ))
              : null
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="departamento_id">Departamento</Label>
          <Input 
            type="select" 
            name="departamento_id" 
            id="departamento_id"
            onChange = {actualizaState}
            value = {datosform.departamento_id}
            >
            <option value="0" >Seleccion una opción </option>
            {
              departamentos ? 
                departamentos.map(item=>(
                  <option value={item.id} >{ item.desc_departamento }</option>
                ))
              : null
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="name">Nombre del alumno</Label>
          <Input 
            type="text" 
            name="nombre_completo" 
            id="nombre_completo" 
            onChange = {actualizaState}
            value = {datosform.nombre_completo}
            />
        </FormGroup>
        <FormGroup>
          <Label for="matricula">Matricula</Label>
          <Input 
            type="text" 
            name="matricula" 
            id="matricula" 
            onChange = {actualizaState}
            value = {datosform.matricula}
            />
        </FormGroup>
        <FormGroup>
          <Label for="descripcion_solicitud">Teléfono:</Label>
          <Input 
            type="text" 
            name="descripcion_solicitud" 
            id="descripcion_solicitud" 
            onChange = {actualizaState}
            value = {datosform.descripcion_solicitud}
            />
        </FormGroup>
        <FormGroup>
          <Label for="estatus_id">Estatus</Label>
          <Input 
            type="select" 
            name="estatus_id" 
            id="estatus_id"
            onChange = {actualizaState}
            value = {datosform.estatus_id}
            >
            <option value="0" >Seleccion una opción </option>
            {
              estatuses ? 
              estatuses.map(item=>(
                <option value={item.id} >{ item.desc_estatus }</option>
                ))
              : null
            }
          </Input>
        </FormGroup>
        {
          mensaje.status ?
            <UncontrolledAlert color={mensaje.label}>
              {mensaje.message}
            </UncontrolledAlert>
          : 
          <Fragment>
            <Button color="primary" type="submit">Guardar</Button>
            <Button onClick = { ()=>{window.location = '/calendario/?calendario=1&fol='+datosform.folio+'&actualizar=false';} }  style={{ marginLeft: 20 }} color="success" type="button"  >Agendar Calendario 1</Button>
            <Button onClick = { ()=>{window.location = '/calendario2/?calendario=2&fol='+datosform.folio+'&actualizar=false';} }  style={{ marginLeft: 20 }} color="success" type="button"  >Agendar Calendario 2</Button>
          </Fragment>
        }
      </Form>
      </CardBody>
      </Card>
    </Col>
  </Row>
  )
}

export default Solicitud