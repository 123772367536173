import React, {  Fragment, useState, useEffect } from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import { FormGroup, Input, UncontrolledAlert, Col, Row, Card, CardBody } from 'reactstrap';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'

import { mensajeAlerta, getData, updateItem, insertDataJson, formatearFecha, validaFechaExistente, getFecha, sumarMinutos } from '../../vibe/helpers/helpers'

const  Calendario = ( {location } ) => {
  const [departamentos, setDepartamentos] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [fechaprogramada , setFechaProgramada] = useState([]);
  const localizer = momentLocalizer(moment)
  const [fechaSeleccion, setFechaSeleccion] = useState(new Date())
  const [folio, setFolio] = useState([]);
  
  const [mensaje, actualizaMensaje] = useState({
    status: false,
    message:'',
    label: 'danger'
  });
  
  const handleSelect =  async ({ start, end, resourceId }) => {
    const queryString = require('query-string');
    const { calendario, fol, actualizar, id } = queryString.parse(location.search);
    const folio = window.prompt('Escriba el folio:',fol);
    const datos_folio = {
      folio: folio,
    }
    const min_tramite = await insertDataJson('solicitud-folio', datos_folio);
    let fecha_hora_final = await sumarMinutos(start, 20); 
    if(min_tramite.data){
      fecha_hora_final = await sumarMinutos(start, parseInt(min_tramite.data.tiempo_tramite_min)); 
    }
    if (folio)
      var datos = {
        folio: folio,
        fecha_agenda: await formatearFecha(start, 'fecha'),
        hora_agenda: moment(start).format('HH:mm:ss'),
        fecha_hora_agenda_inicio: start.toString(),
        fecha_hora_agenda_fin: fecha_hora_final.toString(),
        num_calendario: calendario
      };
      const ruta = 'agenda';
      let send; 
      if(actualizar === 'true'){
        let send = await updateItem(ruta+'/'+id,datos);
        if(send.code === 200){
          getAgenda();
          return;
        }
      }else{
        let send = await insertDataJson(ruta,datos);
        if(send.code === 201){
          getAgenda();
          return;
        }
  
      }

      if(send.code === 404){
        actualizaMensaje({
          status: true,
          label: "danger",
          message: "El folio no existe, favor de verificar"
        })
        return;
      }
      
      if(send.code === 401){
        actualizaMensaje({
          status: true,
          label: "warning",
          message: `El folio ya ha sido agendado consulte la tabla de seguimiento `
        })
        return;
      }
  }

  const getDepartamento = async()=>{
    const send = await getData('departamento');
    let departaments = [];
    if(send.data){
      send.data.map(item=>{
        departaments.push({
          resourceId: item.id, resourceTitle: item.desc_departamento 
        })
      });
      setDepartamentos(departaments);
      return;
    }
  } 

  const getAgendas = async (date)=>{
    //getAgenda(date);
    claculoFechaProgramada(date, agendas, fechaprogramada);
  }

  const getAgenda = async( date )=>{
    const queryString = require('query-string');
    const { calendario } = queryString.parse(location.search);
    const send = await getData('agenda/'+calendario);
    let agend = [];
    console.log(send.data);
    if(send.data){
      send.data.map(item=>{
        agend.push({
          id: item.agenda_id,
          title: item.folio,
          start: new Date((moment(item.fecha_hora_agenda_inicio).format('YYYY')), moment(item.fecha_hora_agenda_inicio).format('M')-1, moment(item.fecha_hora_agenda_inicio).format('DD'), moment(item.fecha_hora_agenda_inicio).format('HH'), moment(item.fecha_hora_agenda_inicio).format('mm'), 0),
          end: new Date((moment(item.fecha_hora_agenda_fin).format('YYYY')), moment(item.fecha_hora_agenda_fin).format('M')-1, moment(item.fecha_hora_agenda_fin).format('DD'), moment(item.fecha_hora_agenda_fin).format('HH'), moment(item.fecha_hora_agenda_fin).format('mm'), 0),
          resourceId: parseInt(item.departamento_id),
          status: 0,
          status_entrega: item.status_entrega
        })
      });
      let fecha_actual = await getFecha();
      fecha_actual = moment(fecha_actual).format('YYYY-MM-DD');
      if(date){
        fecha_actual = moment(date).format('YYYY-MM-DD');
      }
      if(fechaprogramada.length > 0){
        claculoFechaProgramada(fecha_actual, agend, fechaprogramada);
      }else{
        const send = await insertDataJson('fecha-programada-dia');
        claculoFechaProgramada(fecha_actual, agend, send.data);
      }
    }
  }

  const claculoFechaProgramada = async ( date, agend, agend_programado)=>{      
    agend_programado.map(item=>{
        const start = new Date((moment(date).format('YYYY')), moment(date).format('M')-1, moment(date).format('DD'), moment(Date.parse(item.fecha_inicio + ' ' + item.hora_inicio)).format('HH'), moment(Date.parse(item.fecha_inicio + ' ' + item.hora_inicio)).format('mm'), 0);
        const end = new Date((moment(date).format('YYYY')), moment(date).format('M')-1, moment(date).format('DD'), moment(Date.parse(item.fecha_fin + ' ' + item.hora_fin)).format('HH'), moment(Date.parse(item.fecha_fin + ' ' + item.hora_fin)).format('mm'), 0);
        const existe_progamado = agend.filter(item2=>(item2.start).toString() === (start).toString());
        if(existe_progamado.length > 0){
          return;
        }
        var existe = validaFechaExistente(date, item.fecha_inicio, item.fecha_fin);
        if(existe){
          agend.push({
            id: item.id,
            title: 'No disponible',
            start: start,
            end: end,
            resourceId: item.departamento_id,
            status: 1
          });

          setAgendas(agend);
        }
      })
      setAgendas(agend);
  }

  const getFechaProgramada = async () =>{
    const send = await insertDataJson('fecha-programada-dia');
    if(send.data){
      await setFechaProgramada(send.data);
      getAgenda();
      return;
    }
  }

  const actualizaState = e =>{
    setFechaSeleccion(e.target.value)
  }

  useEffect(()=>{
    getDepartamento();
    getFechaProgramada();
    const queryString = require('query-string');
    const { folio } = queryString.parse(location.search);
    if(folio){
      setFolio(folio);
    }
  },[]);


  return(
    <Fragment>
      <Row>
        <Col md={{ size: 6 }}>
          <FormGroup>
            <Input 
              type="date" 
              name="fecha" 
              id="fecha" 
              onChange = {actualizaState}
              value = { fechaSeleccion }
              />
          </FormGroup>
        </Col>
        <Col md={{ size: 12 }}>
          {
            mensaje.status ?
              <UncontrolledAlert color={mensaje.label}>
                {mensaje.message}
              </UncontrolledAlert>
            : null
          }
          <Card>
            <CardBody>
            <Calendar
              selectable
              events={agendas}
              localizer={localizer}
              defaultView={Views.DAY}
              views={['day', 'week']}
              step={10}
              defaultDate={fechaSeleccion}
              date = {fechaSeleccion}
              resources={departamentos}
              resourceIdAccessor="resourceId"
              resourceTitleAccessor="resourceTitle"
              messages={{
                next: "Siguiente",
                previous: "Anterior",
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día"
              }}
              min={new Date(2022, 10, 0, 9, 30, 0)}
              max={new Date(2022, 10, 0, 19, 0, 0)} 
              onSelectSlot={handleSelect}
              eventPropGetter={(event) => ({
                style: { 
                  backgroundColor: event.status === 1 ? '#ff47697d' : parseInt(event.status_entrega) === 1 ? '#17a2b8' : '#157bd3',
                  border: 0
                }
              })}
              onSelectEvent={event => mensajeAlerta(event.title,event.title)}
              onNavigate={date => {
                getAgendas(date);
              }}
            />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Calendario